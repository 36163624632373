import React, { FC } from "react";
import { View, Text, Image } from "@react-pdf/renderer";
import { styles } from "./Styles";
import { QuoteObj } from "../../GlobalTypes";

interface HeaderProps {
  data: QuoteObj | {};
  customerOrDealer: string;
  fixed?: boolean;
  title: string;
  dealerLogo?: string;
  salesOrder: string;
  summaryTitle?: boolean | string;
  page1?: boolean;
}

const Header: FC<HeaderProps> = ({
  data,
  customerOrDealer,
  fixed,
  title,
  dealerLogo,
  salesOrder,
  summaryTitle,
  page1,
}) => {
  const timeElapsed = Date.now();
  const today = new Date(timeElapsed).toLocaleDateString();
  const time = new Date(timeElapsed).toLocaleTimeString();

  return (
    <View fixed={fixed} style={styles.header}>
      <View style={styles.company}>
        <Image
          style={!dealerLogo ? styles.logo : styles.smallLogo}
          src="https://res.cloudinary.com/ag-millworks/image/upload/AG_Logos/AGlogoBlack.png"
        />
        <View style={[styles.row, { paddingLeft: 20 }]}>
          <View style={[styles.column, { paddingRight: 10 }]}>
            <Text style={styles.address}>3111 Golf Course Dr</Text>
            <Text style={styles.address}>Ventura, CA 93003</Text>
          </View>
          <View style={[styles.column, { paddingLeft: 10 }]}>
            <Text style={styles.address}>T: 1 (800) 559-9995</Text>
            <Text style={styles.address}>F: (805) 644-4493</Text>
          </View>
        </View>
        <View style={styles.column}>
          <Text
            style={styles.pageAndDate}
            render={({ pageNumber, totalPages }) =>
              `Page ${pageNumber} of ${totalPages}`
            }
          />
          {!("dateOrdered" in data) || !data.dateOrdered || page1 ? (
            <Text style={styles.pageAndDate}>
              Time/Date: {today} {time}
            </Text>
          ) : data.dateOrdered && !data.depositReceivedDate && !page1 ? (
            <View style={styles.timeDate}>
              <Text style={styles.pageAndDate2}>
                Time/Date: {today} {time}
              </Text>
              {title === "ORDER ACKNOWLEDGEMENT" 
              ? <Text /> 
              : <Text style={[styles.pageAndDate2, { paddingLeft: 10 }]}>
                {" "}
                Sign-Off Date:{" "}
                {new Date(data.dateOrdered).toLocaleDateString("en-US")}
              </Text>
              }
            </View>
          ) : data.dateOrdered && data.depositReceivedDate && !page1 ? (
            <View style={styles.timeDate}>
              <Text style={styles.pageAndDate2}>
                Time/Date: {today} {time}
              </Text>
              <Text style={[styles.pageAndDate2, { paddingLeft: 10 }]}>
                {" "}
                Deposit Received:{" "}
                {new Date(data.depositReceivedDate).toLocaleDateString(
                  "en-US",
                  { timeZone: "UTC" },
                )}
              </Text>
            </View>
          ) : (
            <Text />
          )}
        </View>
      </View>

      {dealerLogo ? (
        <Image style={styles.dealerLogo} src={dealerLogo} />
      ) : customerOrDealer === "Production" && data["salesOrder"] ? 
        <Image style={styles.barcode} src={`https://barcodeapi.org/api/code39/${data["salesOrder"]}?text=none`} />
        :
        <Text />
      }

      <View style={styles.dealerInfo}>
        {customerOrDealer === "CUSTOMER" ? (
          <View style={styles.customerQDiv}>
            {!summaryTitle ? (
              <Text style={styles.customer}>CUSTOMER {title}</Text>
            ) : (
              <Text style={styles.customer}>
                CUSTOMER {title.replace(" ", ` ${summaryTitle} `)}
              </Text>
            )}
          </View>
        ) : customerOrDealer === "Parts" ? (
          <Text style={styles.dealer}>{title}</Text>
        ) : customerOrDealer === "Production" ? (
          <Text style={styles.dealer}>PRODUCTION ORDER</Text>
        ) : title === "ORDER ACKNOWLEDGEMENT" ? (
          <Text style={styles.dealer}>{title}</Text>
        ) : (
          <Text style={styles.dealer}>DEALER {title}</Text>
        )}

        {title === "ORDER ACKNOWLEDGEMENT" ? (
          <>
            <Text style={styles.quoteNumber}>Purchase Order #: {"poNumber" in data && data.poNumber ? data.poNumber : "N/A"}</Text>
            <Text style={styles.quoteNumber}>Sales Order #: {salesOrder}</Text>
            <Text style={styles.quoteNumber}>
              Quote #: PC-{"quoteNumber" in data ? data.quoteNumber : "N/A"}
            </Text>
          </>
        ) : !salesOrder ? (
          <Text style={styles.quoteNumber}>
            Quote #: PC-{"quoteNumber" in data ? data.quoteNumber : "N/A"}
          </Text>
        ) : (
          <>
            <Text style={styles.quoteNumber}>Sales Order #: {salesOrder}</Text>
            <Text style={styles.quoteNumber}>
              Quote #: PC-{"quoteNumber" in data ? data.quoteNumber : "N/A"}
            </Text>
          </>
        )}

        {title === "ORDER ACKNOWLEDGEMENT"
        ?
        <Text style={[styles.text, { fontSize: 10 }]}>
         AG MILLWORKS
        </Text>
        :
        <>
        <Text style={[styles.text, { fontSize: 10 }]}>
          Dealer: {"dealer" in data ? data.dealer : "N/A"}
        </Text>
        <Text style={[styles.text, { fontSize: 10 }]}>
          Dealer Sales Rep: {"salesperson" in data ? data.salesperson : "N/A"}
        </Text>
        </>
        }
        
      </View>
    </View>
  );
};

export default Header;
