import { StyleSheet } from "@react-pdf/renderer";
import "../fontRegister";

export const styles = StyleSheet.create({
  barcode: {
    width: "auto",
    height: 60,
    padding: 5
  },
  column: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
  },
  liteCorner: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    paddingTop: 20,
    paddingBottom: 10,
    // position: "absolute",
    // top: 150,
    // right: 20
  },
  innerColumn1: {
    display: "flex",
    justifyContent: "flex-start",
    flexDirection: "column",
    paddingRight: 7,
  },
  innerColumn2: {
    display: "flex",
    justifyContent: "flex-start",
    flexDirection: "column",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: "1px solid black",
    flexDirection: "row",
    marginTop: 10,
  },
  logo: {
    height: 50,
    width: 170,
    padding: 5,
  },
  text: {
    fontSize: 8,
    lineHeight: 1.8,
    fontFamily: "Roboto",
  },
  refKeyText: {
    fontSize: 8,
    lineHeight: 1.5,
    fontFamily: "Roboto",
    padding: 2,
  },
  refKeyTextLetter: {
    fontSize: 8,
    lineHeight: 1.5,
    fontFamily: "Roboto",
    padding: 2,
    borderBottom: "1px solid black"
  },
  disclosures: {
    fontSize: 8,
    lineHeight: 3,
    fontFamily: "Roboto",
  },
  disclosuresBold: {
    fontSize: 8,
    fontWeight: "bold",
    lineHeight: 3,
    fontFamily: "Roboto-Bold",
  },
  textBold: {
    fontSize: 8,
    lineHeight: 1.8,
    fontWeight: "bold",
    fontFamily: "Roboto-Bold",
  },
  unitNumTxt: {
    fontWeight: "bold",
    fontFamily: "Roboto-Bold",
    fontSize: 10,
    border: "1px solid black",
    padding: 2,
    lineHeight: 1,
  },
  details: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    // alignItems: 'flex-start',
  },
  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  rowStart2: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "flex-start",
    width: "100%",
    position: "relative",
  },
  innerRow1: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "flex-start",
  },
  colWithMargin: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
  },
  address: {
    fontSize: 6,
    lineHeight: 1.2,
  },
  company: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    marginBottom: 10,
    marginLeft: 20,
  },
  dealerInfo: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    marginRight: 20,
  },
  dealer: {
    fontSize: 14,
    fontWeight: "bold",
    marginBottom: 20,
    fontFamily: "Roboto-Bold",
  },
  quoteNumber: {
    fontSize: 10,
    fontWeight: "bold",
    fontFamily: "Roboto-Bold",
  },
  pageAndDate: {
    fontSize: 8,
    alignSelf: "flex-start",
    fontWeight: "bold",
    fontFamily: "Roboto-Bold",
    marginTop: 5,
  },
  bigBold: {
    fontSize: 14,
    fontWeight: "bold",
    padding: 5,
    fontFamily: "Roboto",
  },
  page1TextLeft: {
    fontSize: 12,
    lineHeight: 1.2,
    fontWeight: "bold",
    fontFamily: "Roboto-Bold",
  },
  page1TextRight: {
    fontSize: 12,
    lineHeight: 1.2,
    fontFamily: "Roboto",
  },
  refKeyRow: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    padding: 2,
    borderBottom: "1px solid black",
  },
  sliderScreen: {
    width: "auto",
    height: "auto",
    maxWidth: "75%",
    maxHeight: 70,
    marginTop: -20,
    marginLeft: 45,
    paddingBottom: 5,
  },
  sliderScreenSmall: {
    width: "auto",
    height: "auto",
    maxWidth: 200,
    maxHeight: 50,
    marginTop: -10,
    marginRight: -20,
    paddingBottom: 5,
  },
  screenImg: {
    width: "auto",
    height: "auto",
    maxWidth: "65%",
    maxHeight: 70,
    marginTop: -20,
    marginLeft: 45,
  },
  screenImgSmall: {
    width: "auto",
    height: "auto",
    maxWidth: 210,
    maxHeight: 50,
    marginTop: -10,
    marginRight: 15,
  },
  screenImgXSmall: {
    width: "auto",
    height: "auto",
    maxWidth: 130,
    maxHeight: 50,
    marginTop: -10,
    marginRight: -20,
  },
  screenImgSwing: {
    width: "auto",
    height: "auto",
    maxWidth: "60%",
    maxHeight: 50,
    marginTop: -25,
    marginRight: -60,
  },
  screenImgSwingSmall: {
    width: "auto",
    height: "auto",
    maxWidth: 150,
    maxHeight: 50,
    marginTop: -25,
    marginRight: -40,
  },
  sectionImg: {
    width: 250,
    height: 220,
  },
  sectionImgSwing: {
    width: 300,
    height: 220,
  },
  jambSectionSliderWide: {
    width: 320,
    height: 220,
  },
  jambSectionSlider: {
    width: 250,
    height: 220,
  },
  refKeyCol: {
    width: "100%",
    borderBottom: "1px solid black",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  customerQDiv: {
    backgroundColor: "black",
    borderRadius: 5,
    marginBottom: 20,
  },
  customer: {
    fontSize: 14,
    color: "white",
    fontWeight: "bold",
    fontFamily: "Roboto-Bold",
    padding: 5,
  },
  elevationView: {
    width: 450,
    height: 270,
    paddingLeft: 10,
    marginTop: -10,
  },
  elevationViewFixedPanel: {
    width: 210,
    height: 270,
    paddingLeft: 10,
    marginTop: -10,
  },
  planViewBfoldSmall: {
    width: "auto",
    height: "auto",
    maxWidth: 350,
    maxHeight: 100,
    marginTop: -30,
    alignSelf: "flex-start",
  },
  planViewFixedPanel: {
    width: "auto",
    height: "auto",
    maxWidth: 300,
    maxHeight: 75,
    marginTop: -20,
    alignSelf: "flex-start",
  },
  planViewBfold: {
    width: "auto",
    height: "auto",
    maxWidth: "100%",
    maxHeight: 100,
    marginTop: -30,
    alignSelf: "flex-start",
  },
  planViewCorner: {
    width: 387,
    height: 300,
    zIndex: -1,
    top: 0,
    position: "absolute",
  },
  planViewCornerWrap: {
    // width: "auto",
    // height: "auto",
    width: 387,
    height: 300,
    position: "relative",
    alignSelf: "flex-start",
    textAlign: "center",
  },
  planViewConfigTxt: {
    position: "absolute",
    fontWeight: "bold",
    fontFamily: "Roboto-Bold",
    zIndex: 10,
    fontSize: 18,
  },
  planViewSwing: {
    width: "auto",
    height: "auto",
    maxWidth: "100%",
    maxHeight: 100,
    marginTop: -30,
    alignSelf: "flex-start",
  },
  fixedBox: {
    position: "absolute",
    left: "45%",
    bottom: 20,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    zIndex: 100,
    width: 200,
  },
  fixedBox2: {
    position: "absolute",
    left: "45%",
    bottom: -100,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    zIndex: 100,
    width: 200,
  },
  dateSigned: {
    color: "white",
    fontSize: 8,
    position: "absolute",
    bottom: -20,
  },
  initialsBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    bottom: 15,
    left: 20,
  },
  rowSpacing: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    paddingTop: 5,
  },
  unitNum: {
    position: "absolute",
    left: 10,
    top: 0,
    display: "flex",
    justifyContent: "space-around",
    flexDirection: "row",
    width: 50,
  },
  rowStart: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    marginTop: 5,
  },
  evpvCol: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
});
