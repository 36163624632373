import { calculatePanelHeight } from "./calculatePanelHeight";
import { calculatePanelWidth } from "./calculatePanelWidth";
import { convertDecimalToFraction } from "./convertDecimalToFraction";
import fractionToInt from "../fractionToInt";
import { UnitObj } from "../quoteCartStorage";

function calculateScreenSize(
  unit: UnitObj
): string | { left: string; right: string } {
  const isCorner = unit.doorType?.includes("Corner");

  const hasSlidingScreen =
    unit.doorType === "Slider" &&
    unit.screenType === "Sliding Screen" &&
    unit.addSlidingScreen
      ? true
      : false;

  const isCentor =
    unit.screenType?.includes("Centor") && unit.addScreen ? true : false;

  if (hasSlidingScreen) {
    const netWidth = fractionToInt(unit.unitSize, unit.unitSizeFraction);
    const netHeight = fractionToInt(unit.height, unit.heightFraction);

    const panelWidth = calculatePanelWidth(
      unit.doorType,
      netWidth,
      Number(unit.numberOfPanels),
      unit.material,
      unit.exactConfig,
      unit.stilesAndRails,
      unit.addScreen && unit.screenType ? unit.screenType : "",
      "",
      "",
      "",
      unit.handleStyle
    );
    const panelHeight = calculatePanelHeight(
      unit.exactConfig,
      unit.doorType,
      unit.sillStyle || "",
      netHeight,
      unit.addScreen && unit.screenType ? unit.screenType : "",
      unit.material
    );

    const pwString = convertDecimalToFraction(panelWidth || 0, true);
    const phString = convertDecimalToFraction(panelHeight, true);

    return `${pwString}" X ${phString}"`;
  } else if (isCentor) {
    const netWidth = fractionToInt(unit.unitSize, unit.unitSizeFraction);
    const netHeight = fractionToInt(unit.height, unit.heightFraction);

    let [centorWidth, centorHeight] = [0, 0];

    if (unit.doorType === "Slider") {
      centorHeight = unit.sillStyle?.includes("Steel")
        ? netHeight + 1.1792
        : netHeight - 0.5625;
    } else {
      centorHeight = unit.sillStyle?.includes("Ultra")
        ? netHeight + 1.1875
        : netHeight - 0.5625;
    }

    if (unit.doorType === "Slider" && unit.sliderPocket === "With Pocket") {
      centorWidth = unit.exactConfig.includes("-")
        ? netWidth + 4.1875 + 4.1875
        : netWidth - 0.5 + 4.1875;
    } else {
      centorWidth = netWidth - 1;
    }

    const centorWidthStr = convertDecimalToFraction(centorWidth, true);
    const centorHeightStr = convertDecimalToFraction(centorHeight, true);

    const centorScreenSize = `${centorWidthStr}" X ${centorHeightStr}"`;

    if (isCorner) {
      const netWidth = fractionToInt(
        unit.cornerWidthRight || "",
        unit.cornerWidthFractionRight
      );
      const netHeight = fractionToInt(unit.height, unit.heightFraction);

      let [centorWidth, centorHeight] = [netWidth - 1, 0];

      centorHeight = unit.sillStyle?.includes("Ultra")
        ? netHeight + 1.1875
        : unit.doorType === "Swing"
        ? netHeight - 0.5625
        : netHeight - 0.5;

      const centorWidthStr = convertDecimalToFraction(centorWidth, true);
      const centorHeightStr = convertDecimalToFraction(centorHeight, true);

      const centorScreenSizeRightCorner = `${centorWidthStr}" X ${centorHeightStr}"`;
      return { left: centorScreenSize, right: centorScreenSizeRightCorner };
    }
    return centorScreenSize;
  } else if (unit.addScreen) {
    const screenHeight = unit.heightFraction
      ? `${unit.height} ${unit.heightFraction}"`
      : `${unit.height}"`;
    const screenSize =
      unit.screenPocket && unit.netFrameWidthWithPocket
        ? `${unit.netFrameWidthWithPocket}" X ${screenHeight}`
        : `${unit.unitSize} ${unit.unitSizeFraction || ""}" X ${screenHeight}`;
    if (isCorner) {
      return {
        left: screenSize,
        right:
          unit.screenPocket && unit.netFrameWidthWithPocketRightCorner
            ? `${unit.netFrameWidthWithPocketRightCorner}" X ${screenHeight}`
            : `${unit.cornerWidthRight} ${
                unit.cornerWidthFractionRight || ""
              }" X ${screenHeight}`,
      };
    }

    return screenSize;
  }

  return "";
}

export default calculateScreenSize;
