import React, { FC } from "react";
import { View, Text, StyleSheet } from "@react-pdf/renderer";
import "../fontRegister";
import { UnitObj } from "../../util/quoteCartStorage";
import calculateScreenSize from "../../util/pdfFormulas/calculateScreenSize";

const styles = StyleSheet.create({
  table: {
    width: "auto",
    borderStyle: "solid",
    borderColor: "black",
    borderWidth: 2,
    borderRightWidth: 1,
    borderBottomWidth: 1,
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
  },
  tableColHeader1: {
    width: "50%",
    borderStyle: "solid",
    borderColor: "black",
    borderBottomColor: "black",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCellHeader: {
    margin: "auto",
    padding: 2,
    fontSize: 8,
    fontWeight: 500,
  },
  tableCellHeaderBold: {
    margin: "auto",
    fontFamily: "Roboto-Bold",
    fontWeight: "bold",
    padding: 2,
    fontSize: 8,
  },
  body: {
    padding: 10,
    width: 180,
  },
});

interface ScreenSizeProps {
  unit: UnitObj;
  cornerSide: string;
}

const ScreenSize: FC<ScreenSizeProps> = ({ unit, cornerSide }) => {
  const screenSize = calculateScreenSize(unit);

  if(screenSize === "") return null

  const screenLabel = () => {
    if(unit.addScreen?.includes("Double")){
      return unit.addScreen.includes("Shade") ? "DOUBLE SHADE" : "DOUBLE SCREEN"
    }

    return unit.addScreen?.includes("Shade") ? "SHADE" : "SCREEN"
  }

  return (
    <>
        <View style={styles.body}>
          <View style={styles.table}>
            <View style={styles.tableRow}>
              <View style={styles.tableColHeader1}>
                <Text style={styles.tableCellHeaderBold}>{screenLabel()}</Text>
              </View>

              <View style={styles.tableColHeader1}>
                <Text style={styles.tableCellHeader}>{
                    typeof screenSize === "string"
                    ? screenSize
                    : screenSize[cornerSide]
                }
                </Text>
              </View>
            </View>
          </View>
        </View>
    </>
  );
};

export default ScreenSize;
