import { convertDecimalToFraction } from "../../../util/pdfFormulas/convertDecimalToFraction";

function calculateScreenPocket(netWidth: number, pocket: string, key: string) {
  let pocketSize = 0

  const width = !pocket?.includes("Double Pocket") ? netWidth : netWidth / 2;

  //07/31/24 peer Antonio: if unit has a pocket and width is exactly 120 for example go to next category

  if (width < 48) {
    pocketSize = 3.5;
  } else if (width < 72) {
    pocketSize = 4.25;
  } else if (width < 96) {
    pocketSize = 4.75;
  } else if (width < 120) {
    pocketSize = 5;
  } else if (width < 144) {
    pocketSize = 5.75;
  } else if (width < 168) {
    pocketSize = 6.25;
  } else {
    pocketSize = 6.75;
  }

  const screenSize = width + pocketSize;

  if (screenSize < 48) {
    pocketSize = 3.5;
  } else if (screenSize < 72) {
    pocketSize = 4.25;
  } else if (screenSize < 96) {
    pocketSize = 4.75;
  } else if (screenSize < 120) {
    pocketSize = 5;
  } else if (screenSize < 144) {
    pocketSize = 5.75;
  } else if (screenSize < 168) {
    pocketSize = 6.25;
  } else {
    pocketSize = 6.75;
  }

  switch (key) {
    case "pocketCalc1":
      pocketSize -= 1;
      break;
    case "pocketCalc_875":
      pocketSize += 0.875;
      break;
    case "pocketCalc_9375":
      pocketSize -= 0.9375;
      break;
    case "pocketCalc1_5625":
      pocketSize += 1.5625;
      break;
    case "pocketCalc1_5":
      pocketSize += 1.5;
      break;
    default:
      pocketSize += 0;
  }

  return convertDecimalToFraction(pocketSize);
}

export default calculateScreenPocket;
